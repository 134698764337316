export const schools = [
  { name: "Abilene Christian University" },
  { name: "Academy of Art College" },
  { name: "Adams State College" },
  { name: "Adelphi University" },
  { name: "Adler School of Professional Psychology" },
  { name: "Adrian College" },
  { name: "Agnes Scott College" },
  { name: "Air Force Institute of Technology" },
  { name: "Alabama Agricultural and Mechanical University" },
  { name: "Alabama State University" },
  { name: "Alaska Bible College" },
  { name: "Alaska Pacific University" },
  { name: "Albany College of Pharmacy" },
  { name: "Albany Law School" },
  { name: "Albany Medical Center" },
  { name: "Albany State University" },
  { name: "Albertus Magnus College" },
  { name: "Albion College" },
  { name: "Albright College" },
  { name: "Alcorn State University" },
  { name: "Alderson Broaddus College" },
  { name: "Alfred Adler Graduate School" },
  { name: "Alfred University" },
  { name: "Alice Lloyd College" },
  { name: "Allegheny College" },
  { name: "Allen University" },
  { name: "Alma College" },
  { name: "Alvernia College" },
  { name: "Alverno College" },
  { name: "Ambassador University" },
  { name: "Amber University" },
  { name: "American Academy of Nutrition" },
  { name: "American Business & Technology University" },
  { name: "American Conservatory of Music" },
  { name: "American Conservatory Theater" },
  { name: "American-European School of Management " },
  {
    name:
      "American Film Institute Center for Advanced Film and Television Studies",
  },
  { name: "American Indian College" },
  { name: "American InterContinental University - Atlanta" },
  { name: "American InterContinental University - Ft. Lauderdale" },
  { name: "American InterContinental University - Georgia" },
  { name: "American InterContinental University Online" },
  { name: "American International College" },
  { name: "American Jewish University" },
  { name: "American Military University" },
  { name: "American Public University" },
  { name: "American University" },
  { name: "American World University" },
  { name: "Amherst College" },
  { name: "Anderson College-college" },
  { name: "Anderson University" },
  { name: "Andon College - Modesto" },
  { name: "Andon College - Stockton" },
  { name: "Andrew Jackson University" },
  { name: "Andrews University" },
  { name: "Angelo State University" },
  { name: "Anna Maria College-maria" },
  { name: "Antioch New England Graduate School" },
  { name: "Antioch University" },
  { name: "Antioch University Los Angeles" },
  { name: "Antioch University Santa Barbara" },
  { name: "Antioch University Seattle" },
  { name: "Appalachian Bible College" },
  { name: "Appalachian State University" },
  { name: "Aquinas College" },
  { name: "Arcadia University" },
  { name: "Argosy University" },
  { name: "Argosy University - Hawaii" },
  { name: "Arizona Christian University" },
  { name: "Arizona State University" },
  { name: "Arizona State University, Downtown Phoenix Campus" },
  { name: "Arizona State University, Polytechnic Campus" },
  { name: "Arizona State University, Tempe Campus" },
  { name: "Arizona State University, West Campus" },
  { name: "Arkansas State University" },
  { name: "Arkansas State University, Beebe" },
  { name: "Arkansas State University, Mountain Home" },
  { name: "Arkansas State University, Newport" },
  { name: "Arkansas Tech University" },
  { name: "Arlington Baptist College" },
  { name: "Armstrong Atlantic State University" },
  { name: "Armstrong University" },
  { name: "Art Academy of Cincinnati" },
  { name: "Art Center College of Design" },
  { name: "Arthur D. Little Management Education Institute" },
  { name: "Art Institute of Charlotte" },
  { name: "Art Institute of Southern California" },
  { name: "Asbury College" },
  { name: "Ashland University" },
  { name: "Assumption College" },
  { name: "Athenaeum of Ohio" },
  { name: "Athens State College" },
  { name: "Atlanta Christian College" },
  { name: "Atlanta College of Art" },
  { name: "Atlantic International University" },
  { name: "Atlantic Union College" },
  { name: "Atlantic University" },
  { name: "Auburn University" },
  { name: "Auburn University at Montgomery" },
  { name: "Audrey Cohen College" },
  { name: "Augsburg College" },
  { name: "Augustana College" },
  { name: "Augustana College" },
  { name: "Augusta State University" },
  { name: "Aurora University" },
  { name: "Austin College" },
  { name: "Austin Community College" },
  { name: "Austin Peay State University" },
  { name: "Ave Maria University" },
  { name: "Averett College" },
  { name: "Avila College" },
  { name: "Azusa Pacific University" },
  { name: "Babson College" },
  { name: "Baker College of Auburn Hills" },
  { name: "Baker College of Cadillac" },
  { name: "Baker College of Flint" },
  { name: "Baker College of Mount Clemens" },
  { name: "Baker College of Muskegon" },
  { name: "Baker College of Owosso" },
  { name: "Baker College of Port Huron" },
  { name: "Baker University" },
  { name: "Baldwin-Wallace College" },
  { name: "Ball State University" },
  { name: "Baltimore Hebrew University" },
  { name: "Baltimore International College" },
  { name: "Bank Street College of Education" },
  { name: "Baptist Bible College of Missouri" },
  { name: "Baptist Bible College of Pennsylvania" },
  { name: "Barat College" },
  { name: "Barber-Scotia College" },
  { name: "Barclay College" },
  { name: "Bard College" },
  { name: "Bard Graduate Center for Studies in the Decorative Arts" },
  { name: "Barnard College - Columbia University" },
  { name: "Barry University" },
  { name: "Bartlesville Wesleyan College" },
  { name: "Barton College" },
  { name: "Bastyr University" },
  { name: "Bates College" },
  { name: "Bauder College" },
  { name: "Baylor College of Dentistry" },
  { name: "Baylor College of Medicine" },
  { name: "Baylor University" },
  { name: "Belhaven College" },
  { name: "Bellarmine College" },
  { name: "Bellevue University" },
  { name: "Bellin College of Nursing" },
  { name: "Belmont Abbey College" },
  { name: "Belmont University" },
  { name: "Beloit College" },
  { name: "Bemidji State Univeristy" },
  { name: "Benedict College" },
  { name: "Benedictine College" },
  { name: "Benedictine University" },
  { name: "Benedictine University, Springfield College in Illinois" },
  { name: "Bennett College" },
  { name: "Bennington College" },
  { name: "Bentley College" },
  { name: "Berea College" },
  { name: "Berean University of the Assemblies of God" },
  { name: "Berklee College of Music" },
  { name: "Berne University" },
  { name: "Berry College" },
  { name: "Bethany College California" },
  { name: "Bethany College Kansas" },
  { name: "Bethany College West Virginia" },
  { name: "Bethel College McKenzie" },
  { name: "Bethel College Mishawaka" },
  { name: "Bethel College Newton" },
  { name: "Beth-El College of Nursing and Health Sciences" },
  { name: "Bethel College St. Paul" },
  { name: "Bethune-Cookman College" },
  { name: "Biola University" },
  { name: "Birmingham-Southern College" },
  { name: "Blackburn College" },
  { name: "Black Hawk College" },
  { name: "Black Hills State University" },
  { name: "Blessing-Rieman College of Nursing" },
  { name: "Bloomfield College" },
  { name: "Bloomsburg University of Pennsylvania" },
  { name: "Bluefield College" },
  { name: "Bluefield State College" },
  { name: "Blue Mountain College" },
  { name: "Bluffton College" },
  { name: "Bob Jones University" },
  { name: "Boise Bible College" },
  { name: "Boise State University" },
  { name: "Boricua College" },
  { name: "Boston Architectural Center" },
  { name: "Boston College" },
  { name: "Boston University" },
  { name: "Bowdoin College" },
  { name: "Bowie State University" },
  { name: "Bowling Green State University" },
  { name: "Bowling Green State University, Firelands" },
  { name: "Bradley University" },
  { name: "Brandeis University" },
  { name: "Brandman University" },
  { name: "Brenau University" },
  { name: "Brescia University" },
  { name: "Brevard College" },
  { name: "Brewton-Parker College" },
  { name: "Breyer State University (Virtual University)" },
  { name: "Briar Cliff College" },
  { name: "Bridgewater College" },
  { name: "Bridgewater State College" },
  { name: "Brigham Young University" },
  { name: "Brigham Young University" },
  { name: "Brigham Young University Hawaii" },
  { name: "Brooklyn Law School" },
  { name: "Brooks Institute of Photography" },
  { name: "Brown University" },
  { name: "Brunswick Community College" },
  { name: "Bryan College" },
  { name: "Bryant and Stratton College" },
  { name: "Bryant University" },
  { name: "Bryn Athyn College of the New Church" },
  { name: "Bryn Mawr College" },
  { name: "Bucknell University" },
  { name: "Buena Vista University" },
  { name: "Burlington College" },
  { name: "Butler University" },
  { name: "Cabrini College" },
  { name: "Caldwell College" },
  { name: "California Baptist College" },
  { name: "California Coast University" },
  { name: "California College for Health Sciences" },
  { name: "California College of Arts and Crafts" },
  { name: "California College of Podiatric Medicine" },
  { name: "California College San Diego" },
  { name: "California Institute of Integral Studies" },
  { name: "California Institute of Technology" },
  { name: "California Institute of the Arts" },
  { name: "California Lutheran University" },
  { name: "California Maritime Academy" },
  { name: "California Polytechnic State University - San Luis Obispo" },
  { name: "California School of Professional Psychology - Berkley/Alameda" },
  { name: "California School of Professional Psychology - Fresno" },
  { name: "California School of Professional Psychology - Los Angels" },
  { name: "California School of Professional Psychology - San Diego" },
  { name: "California State Polytechnic University - Pomona" },
  { name: "California State University, Bakersfield" },
  { name: "California State University, Channel Islands" },
  { name: "California State University, Chico" },
  { name: "California State University, Dominguez Hills" },
  { name: "California State University, Fresno" },
  { name: "California State University, Fullerton" },
  { name: "California State University, Hayward" },
  { name: "California State University, Long Beach" },
  { name: "California State University, Los Angeles" },
  { name: "California State University, Monterey Bay" },
  { name: "California State University, Northridge" },
  { name: "California State University, Sacramento" },
  { name: "California State University, San Bernadino" },
  { name: "California State University, San Marcos" },
  { name: "California State University, Stanislaus" },
  { name: "California University of Management and Sciences" },
  { name: "California University of Pennsylvania" },
  { name: "California Western School of Law" },
  { name: "Calumet College of St. Joseph" },
  { name: "Calvary Bible College" },
  { name: "Calvin College" },
  { name: "Cambridge College" },
  { name: "Cameron University" },
  { name: "Campbellsville College" },
  { name: "Campbell University" },
  { name: "Canisius College" },
  { name: "Capella University" },
  { name: "Capital University" },
  { name: "Capital University Law School" },
  { name: "Capitol College" },
  { name: "Cardinal Stritch University" },
  { name: "Carleton College" },
  { name: "Carlos Albizu University" },
  { name: "Carlow College" },
  { name: "Carnegie Mellon University" },
  { name: "Carroll College Helena" },
  { name: "Carroll College Waukesha" },
  { name: "Carson-Newman College" },
  { name: "Carthage College" },
  { name: "Case Western Reserve University" },
  { name: "Castleton State College" },
  { name: "Catawba College" },
  { name: "Catholic Theological Union" },
  { name: "Cedar Crest College" },
  { name: "Cedarville College" },
  { name: "Centenary College" },
  { name: "Centenary College of Louisiana" },
  { name: "Center for Humanistic Studies" },
  { name: "Central Baptist College" },
  { name: "Central Bible College" },
  { name: "Central Christian College of the Bible" },
  { name: "Central College" },
  { name: "Central Connecticut State University" },
  { name: "Central Methodist College" },
  { name: "Central Michigan University" },
  { name: "Central State University" },
  { name: "Central Washington University" },
  { name: "Centre College" },
  { name: "Chadron State College" },
  { name: "Chaminade University of Honolulu" },
  { name: "Champlain College" },
  { name: "Chapman University" },
  { name: "Charles R. Drew University of Medicine and Science" },
  { name: "Charleston Southern University" },
  { name: "Charter Oak State College" },
  { name: "Chatham College" },
  { name: "Chestnut Hill College" },
  { name: "Cheyney University of Pennsylvania" },
  { name: "Chicago State University" },
  { name: "Chowan College" },
  { name: "Christendom College" },
  { name: "Christian Brothers University" },
  { name: "Christian Heritage College" },
  { name: "Christopher Newport University" },
  { name: "Circleville Bible College" },
  { name: "City University" },
  { name: "City University of New York, Bernard M. Baruch College" },
  { name: "City University of New York, Brooklyn College" },
  { name: "City University of New York, City College" },
  { name: "City University of New York, College of Staten Island" },
  { name: "City University of New York (CUNY) System" },
  {
    name: "City University of New York, Graduate School and University Center",
  },
  { name: "City University of New York, Hunter College" },
  { name: "City University of New York, John Jay College of Criminal Justice" },
  { name: "City University of New York, Lehman College" },
  { name: "City University of New York, Medgar Evers College" },
  {
    name:
      "City University of New York Medical School / Sophie Davis School of Biomedical Education",
  },
  { name: "City University of New York, New York City Technical College" },
  { name: "City University of New York, Queens College" },
  { name: "City University of New York, School of Law at Queens College" },
  { name: "City University of New York, York College" },
  { name: "Claflin College" },
  { name: "Claremont Graduate University" },
  { name: "Claremont Lincoln University" },
  { name: "Claremont McKenna College" },
  { name: "Clarion University" },
  { name: "Clark Atlanta University" },
  { name: "Clarke College" },
  { name: "Clarkson College" },
  { name: "Clarkson University" },
  { name: "Clark University" },
  { name: "Clayton College & State University" },
  { name: "Clearwater Christian College" },
  { name: "Cleary College" },
  { name: "Clemson University" },
  { name: "Cleveland Chiropractic College, Kansas City" },
  { name: "Cleveland Chiropractic College, Los Angeles" },
  { name: "Cleveland Institute of Art" },
  { name: "Cleveland Institute of Music" },
  { name: "Cleveland State University" },
  { name: "Coastal Carolina University" },
  { name: "Coe College" },
  { name: "Cogswell Polytechnical College" },
  { name: "Coker College" },
  { name: "Colby College" },
  { name: "Colby-Sawyer College" },
  { name: "Coleman College" },
  { name: "Colgate University" },
  { name: "CollegeAmerica, Denver" },
  { name: "CollegeAmerica, Phoenix" },
  { name: "College for Creative Studies" },
  { name: "College for Financial Planning" },
  { name: "College for Lifelong Learning" },
  { name: "College Misericordia" },
  { name: "College of Aeronautics" },
  { name: "College of Charleston" },
  { name: "College of DuPage" },
  { name: "College of Idaho" },
  { name: "College of Mount St. Joseph" },
  { name: "College of Mount St. Vincent" },
  { name: "College of New Rochelle" },
  { name: "College of Notre Dame" },
  { name: "College of Notre Dame of Maryland" },
  {
    name:
      "College of Osteopathic Medicine of the Pacific (Western University of Health Sciences)",
  },
  { name: "College of St. Benedict and St. John's University" },
  { name: "College of St. Catherine" },
  { name: "College of St. Elizabeth" },
  { name: "College of St. Joseph" },
  { name: "College of St. Mary" },
  { name: "College of St. Rose" },
  { name: "College of St. Scholastica" },
  { name: "College of the Atlantic" },
  { name: "College of the Holy Cross" },
  { name: "College of the Ozarks" },
  { name: "College of Visual Arts" },
  { name: "College of William and Mary" },
  { name: "Colorado Christian University" },
  { name: "Colorado College" },
  { name: "Colorado School of Mines" },
  { name: "Colorado State University" },
  { name: "Colorado State University-Pueblo" },
  { name: "Colorado Technical University" },
  { name: "Columbia College Chicago" },
  { name: "Columbia College Hollywood" },
  { name: "Columbia College of Missouri" },
  { name: "Columbia College South Carolina" },
  { name: "Columbia Commonwealth University" },
  { name: "Columbia International University" },
  { name: "Columbia Southern University" },
  { name: "Columbia Union College" },
  { name: "Columbia University" },
  { name: "Columbus College of Art and Design" },
  { name: "Columbus State University" },
  { name: "Columbus University" },
  { name: "Community College of Denver" },
  { name: "Concord College" },
  { name: "Concordia College, Ann Arbor" },
  { name: "Concordia College, Bronxville" },
  { name: "Concordia College, Moorhead" },
  { name: "Concordia College, Selma" },
  { name: "Concordia College, Seward" },
  { name: "Concordia College, St. Paul" },
  { name: "Concordia University, Austin" },
  { name: "Concordia University, Irvine" },
  { name: "Concordia University, Mequon" },
  { name: "Concordia University, Portland" },
  { name: "Concordia University, River Forest" },
  { name: "Connecticut College" },
  { name: "Converse College" },
  { name: "Conway School of Landscape Design" },
  { name: "Coppin State College" },
  { name: "Cornell College" },
  { name: "Cornell University" },
  { name: "Cornish College of the Arts" },
  { name: "Cosmopolitan University" },
  { name: "Covenant College" },
  { name: "Cranbrook Academy of Art" },
  { name: "Creighton University" },
  { name: "Crichton College" },
  { name: "Crown College" },
  { name: "Culver-Stockton College" },
  { name: "Cumberland College" },
  { name: "Cumberland University" },
  { name: "Curry College" },
  { name: "Daemen College" },
  { name: "Dakota State University" },
  { name: "Dakota Wesleyan University" },
  { name: "Dallas Baptist University" },
  { name: "Dallas Christian College" },
  { name: "Dana College" },
  { name: "Daniel Webster College" },
  { name: "Danville Area Community College" },
  { name: "Dartmouth College" },
  { name: "Darton College" },
  { name: "Davenport College of Business, Grand Rapids" },
  { name: "Davenport College of Business, Kalamazoo" },
  { name: "Davenport College of Business, Lansing" },
  { name: "Davidson College" },
  { name: "Davis and Elkins College" },
  { name: "Deaconess College of Nursing" },
  { name: "Delaware State University" },
  { name: "Delaware Valley College" },
  { name: "Delta International University" },
  { name: "Delta State University" },
  { name: "Denison University" },
  { name: "Denver Paralegal Institute" },
  { name: "Denver Technical College" },
  { name: "DePaul University" },
  { name: "DePauw University" },
  { name: "DeSales University" },
  { name: "Design Institute of San Diego" },
  { name: "Detroit College of Business" },
  { name: "Detroit College of Business - Flint" },
  { name: "Detroit College of Business - Warren" },
  { name: "Detroit College of Law" },
  { name: "DeVry Institute of Technology, Chicago" },
  { name: "DeVry Institute of Technology, Columbus" },
  { name: "DeVry Institute of Technology, Decatur" },
  { name: "DeVry Institute of Technology, DuPage" },
  { name: "DeVry Institute of Technology, Irving" },
  { name: "DeVry Institute of Technology, Kansas City" },
  { name: "DeVry Institute of Technology, Phoenix" },
  { name: "DeVry Institute of Technology, Pomona" },
  { name: "Dickinson College" },
  { name: "Dickinson State University" },
  { name: "Dillard University" },
  { name: "Divine Word College" },
  { name: "Dixie College" },
  { name: "Doane College" },
  { name: "Dominican College" },
  { name: "Dominican College of San Rafael" },
  { name: "Dominican School of Philosophy and Theology" },
  { name: "Dominican University" },
  { name: "Dominion College" },
  { name: "Dordt College" },
  { name: "Dowling College" },
  { name: "Drake University" },
  { name: "Drew University" },
  { name: "Drexel University" },
  { name: "Drury College" },
  { name: "Duke University" },
  { name: "Duluth Business University" },
  { name: "Duquesne University" },
  { name: "D'Youville College" },
  { name: "Earlham College" },
  { name: "EarthNet Institute" },
  { name: "East Carolina University" },
  { name: "East Central University" },
  { name: "Eastern College" },
  { name: "Eastern Connecticut State University" },
  { name: "Eastern Conservatory of Music" },
  { name: "Eastern Illinois University" },
  { name: "Eastern Kentucky University" },
  { name: "Eastern Mennonite University" },
  { name: "Eastern Michigan University" },
  { name: "Eastern Nazarene College" },
  { name: "Eastern New Mexico University" },
  { name: "Eastern Oregon University" },
  { name: "Eastern Virginia Medical School" },
  { name: "Eastern Washington University" },
  { name: "East Stroudsburg State University" },
  { name: "East Tennessee State University" },
  { name: "East Texas Baptist University" },
  { name: "East-West University" },
  { name: "Eckerd College" },
  { name: "Edgewood College" },
  { name: "Edinboro University of Pennsylvania" },
  { name: "Edison Community College" },
  { name: "Edward Waters College" },
  { name: "Elizabeth City State University" },
  { name: "Elizabethtown College" },
  { name: "Elmhurst College" },
  { name: "Elmira College" },
  { name: "Elms College" },
  { name: "Elon College" },
  { name: "Embry-Riddle Aeronautical University" },
  { name: "Embry Riddle Aeronautical University, Prescott" },
  { name: "Emerson College" },
  { name: "Emmanuel College" },
  { name: "Emmanuel College Georgia" },
  { name: "Emmaus Bible College" },
  { name: "Emory & Henry College" },
  { name: "Emory University" },
  { name: "Emporia State University" },
  { name: "Erskine College" },
  { name: "Eugene Bible College" },
  { name: "Eureka College" },
  { name: "Evangel University" },
  { name: "Evergreen State College" },
  { name: "Excel College" },
  { name: "Excelsior College" },
  { name: "Fairfield University" },
  { name: "Fairleigh Dickinson University, Florham-Madison Campus" },
  { name: "Fairleigh Dickinson University, Teaneck-Hackensack Campus" },
  { name: "Fairmont State College" },
  { name: "Fashion Institute of New York" },
  { name: "Faulkner University" },
  { name: "Fayetteville State University" },
  { name: "Felician College" },
  { name: "Ferris State University" },
  { name: "Ferrum College" },
  { name: "Fielding Institute" },
  { name: "Finch University of Health Sciences - The Chicago Medical School" },
  { name: "Finlandia University" },
  { name: "Fisher College" },
  { name: "Fisk University" },
  { name: "Fitchburg State College" },
  { name: "Flagler College" },
  { name: "Florida Agricultural and Mechanical University" },
  { name: "Florida Atlantic University" },
  { name: "Florida Christian College" },
  { name: "Florida Community College at Jacksonville" },
  { name: "Florida Gulf Coast University" },
  { name: "Florida Institute of Technology" },
  { name: "Florida International University" },
  { name: "Florida Memorial College" },
  { name: "Florida Metropolitan University, Fort Lauderdale College" },
  { name: "Florida Metropolitan University, Tampa College" },
  { name: "Florida Metropolitan University, Tampa College Brandon" },
  { name: "Florida Metropolitan University, Tampa College Lakeland" },
  { name: "Florida Southern College" },
  { name: "Florida State University" },
  { name: "Florida University of Medicine" },
  { name: "Fontbonne College" },
  { name: "Foothill-De Anza Community College District" },
  { name: "Fordham University" },
  { name: "Forest Institute of Professional Psychology" },
  { name: "Fort Hays State University" },
  { name: "Fort Lewis College" },
  { name: "Fort Valley State College" },
  { name: "Framingham State College" },
  { name: "Franciscan School of Theology" },
  { name: "Franciscan University of Steubenville" },
  { name: "Francis Marion University" },
  { name: "Franklin and Marshall College" },
  { name: "Franklin College" },
  { name: "Franklin Pierce College" },
  { name: "Franklin Pierce Law Center" },
  { name: "Franklin University" },
  { name: "Franklin W. Olin College of Engineering" },
  { name: "Frank Lloyd Wright School of Architecture" },
  { name: "Fred Hutchinson Cancer Research Center" },
  { name: "Freed-Hardeman University" },
  { name: "Free University (Virginia Theological University)" },
  { name: "Free Will Baptist Bible College" },
  { name: "Fresno City College" },
  { name: "Fresno Pacific University" },
  { name: "Friends University" },
  { name: "Frostburg State University" },
  { name: "Full Sail University" },
  { name: "Furman University" },
  { name: "Gallaudet University" },
  { name: "Gannon University" },
  { name: "Gardner Webb University" },
  { name: "Gemological Institute of America" },
  { name: "Geneva College" },
  { name: "George Fox University" },
  { name: "George Mason University" },
  { name: "Georgetown College" },
  { name: "Georgetown University" },
  { name: "George Washington University" },
  { name: "George Wythe College" },
  { name: "Georgia Baptist College of Nursing" },
  { name: "Georgia College & State University" },
  { name: "Georgia Health Sciences University" },
  { name: "Georgia Institute of Technology" },
  { name: "Georgian Court College" },
  { name: "Georgia School of Professional Psychology" },
  { name: "Georgia Southern University" },
  { name: "Georgia Southwestern University" },
  { name: "Georgia State University" },
  { name: "Gettysburg College" },
  { name: "Glendale University College of Law" },
  { name: "Glenville State College" },
  { name: "Goddard College" },
  { name: "God's Bible School and College" },
  { name: "Golden Gate University" },
  { name: "Goldey-Beacom College" },
  { name: "Gonzaga University" },
  { name: "Gordon College" },
  { name: "Gordon Conwell Theological Seminary" },
  { name: "Goshen College" },
  { name: "Goucher College" },
  { name: "Governors State University" },
  { name: "Grace Bible College" },
  { name: "Grace College" },
  { name: "Graceland College" },
  { name: "Grace University" },
  { name: "Graduate Theological Union" },
  { name: "Grambling State University" },
  { name: "Grand Canyon University" },
  { name: "Grand Valley State University" },
  { name: "Grand View College" },
  { name: "Grantham University" },
  { name: "Gratz College" },
  { name: "Great Lakes Christian College" },
  { name: "Green Mountain College" },
  { name: "Greensboro College" },
  { name: "Greenville College" },
  { name: "Grinnell College" },
  { name: "Grove City College" },
  { name: "Guilford College" },
  { name: "Gustavus Adolphus College" },
  { name: "Gwynedd-Mercy College" },
  { name: "Hagerstown Community College" },
  { name: "Hamilton College" },
  { name: "Hamilton Technical College" },
  { name: "Hamline University" },
  { name: "Hampden-Sydney College" },
  { name: "Hampshire College" },
  { name: "Hampton College" },
  { name: "Hampton University" },
  { name: "Hannibal-Lagrange College" },
  { name: "Hanover College" },
  { name: "Harding University" },
  { name: "Harding University Graduate School of Religion" },
  { name: "Hardin-Simmons University" },
  { name: "Harrington Institute of Interior Design" },
  { name: "Harris-Stowe State University" },
  { name: "Hartford College for Women" },
  { name: "Hartford Graduate Center (Rensselaer at Hartford)" },
  { name: "Hartwick College" },
  { name: "Harvard University" },
  { name: "Harvey Mudd College" },
  { name: "Hastings College" },
  { name: "Haverford College" },
  { name: "Hawaii Pacific University" },
  { name: "Heartland Baptist Bible College" },
  { name: "Hebrew College" },
  { name: "Heidelberg College" },
  { name: "Henderson State Univerisity" },
  { name: "Hendrix College" },
  { name: "Heritage College" },
  { name: "Hesser College" },
  { name: "High Point University" },
  { name: "Hilbert College" },
  { name: "Hillsdale College" },
  { name: "Hiram College" },
  { name: "Hobart and William Smith Colleges" },
  { name: "Hobe Sound Bible College" },
  { name: "Hodges University" },
  { name: "Hofstra University" },
  { name: "Hollins University" },
  { name: "Holy Apostles College" },
  { name: "Holy Cross College" },
  { name: "Holy Family College" },
  { name: "Holy Names College" },
  { name: "Hood College" },
  { name: "Hope College" },
  { name: "Hope International University" },
  { name: "Houghton College" },
  { name: "Houston Baptist University" },
  { name: "Howard Payne University" },
  { name: "Howard University" },
  { name: "Humboldt State University" },
  { name: "Humphreys College" },
  { name: "Huntington College" },
  { name: "Huron University" },
  { name: "Husson College" },
  { name: "Huston-Tillotson College" },
  { name: "ICI University" },
  { name: "ICT College" },
  { name: "Idaho State University" },
  { name: "Iglobal University" },
  { name: "Illinois Benedictine University" },
  { name: "Illinois College" },
  { name: "Illinois College of Optometry" },
  { name: "Illinois Institute of Technology" },
  { name: "Illinois School of Professional Psychology - Chicago Campus" },
  { name: "Illinois School of Professional Psychology - Meadows Campus" },
  { name: "Illinois State University" },
  { name: "Illinois Valley Community College" },
  { name: "Illinois Wesleyan University" },
  { name: "Immaculata University" },
  { name: "IMPAC University" },
  { name: "Indiana Institute of Technologyy" },
  { name: "Indiana State University" },
  { name: "Indiana University at Bloomington" },
  { name: "Indiana University at Kokomo" },
  { name: "Indiana University at South Bend" },
  { name: "Indiana University - East" },
  { name: "Indiana University - Northwest" },
  { name: "Indiana University of Pennsylvania" },
  { name: "Indiana University-Purdue University at Columbus" },
  { name: "Indiana University-Purdue University at Fort Wayne" },
  { name: "Indiana University-Purdue University at Indianapolis" },
  { name: "Indiana University - Southeast" },
  { name: "Indiana University (System)" },
  { name: "Indiana Wesleyan University" },
  { name: "Institute of Clinical Social Work" },
  { name: "Institute of Paper Science and Technology" },
  { name: "Institute of Textile Technology" },
  { name: "Institute of Transpersonal Psychology" },
  { name: "IntelliTec College - Grand Junction" },
  { name: "International Academy of  Merchandising and Design Chicago" },
  { name: "International Academy of  Merchandising and Design Tampa" },
  { name: "International Bible College" },
  { name: "International College" },
  { name: "Iona College" },
  { name: "Iowa State University of Science and Technology" },
  { name: "Iowa Wesleyan College" },
  { name: "Ithaca College" },
  { name: "ITT Technical Institute Fort Wayne" },
  { name: "ITT Technical Institute Indianapolis" },
  { name: "ITT Technical Institute Maitland" },
  { name: "ITT Technical Institute Portland" },
  { name: "ITT Technical Institute West Covina" },
  { name: "Jackson State University" },
  { name: "Jacksonville State University" },
  { name: "Jacksonville University" },
  { name: "James Madison University" },
  { name: "Jamestown College" },
  { name: "Jarvis Christian College" },
  { name: "John Brown University" },
  { name: "John Carroll University" },
  { name: "John F. Kennedy University" },
  { name: "John Marshall Law School" },
  { name: "John Paul the Great Catholic University" },
  { name: "Johns Hopkins University" },
  { name: "Johnson Bible College" },
  { name: "Johnson County Community College" },
  { name: "Johnson C. Smith University" },
  { name: "Johnson State College" },
  { name: "Johnson & Wales University" },
  { name: "Johnson & Wales University, Charleston" },
  { name: "John Wesley College" },
  { name: "Jones College" },
  { name: "Jones International University" },
  { name: "Judson College Elgin" },
  { name: "Judson College Marion" },
  { name: "Juniata College" },
  { name: "Kalamazoo College" },
  { name: "Kankakee Community College" },
  { name: "Kansas City Art Institute" },
  { name: "Kansas State University" },
  { name: "Kansas Wesleyan University" },
  { name: "Kaplan University" },
  { name: "Katharine Gibbs School" },
  { name: "Kean University of New Jersey" },
  { name: "Keck Graduate Institute of Applied Life Sciences" },
  { name: "Keene State College" },
  { name: "Keller Graduate School of Management" },
  { name: "Kendall College" },
  { name: "Kendall College of Art and Design" },
  { name: "Kennesaw State University" },
  { name: "Kent State University" },
  { name: "Kent State University - Ashtabula" },
  { name: "Kent State University - East Liverpool" },
  { name: "Kent State University - Salem" },
  { name: "Kent State University - Stark" },
  { name: "Kent State University - Trumbull" },
  { name: "Kent State University - Tuscarawas" },
  { name: "Kentucky Christian College" },
  { name: "Kentucky State University" },
  { name: "Kentucky Wesleyan College" },
  { name: "Kenyon College" },
  { name: "Kettering University (GMI)" },
  { name: "Keuka College" },
  { name: "King College" },
  { name: "King's College" },
  { name: "Kirksville College of Osteopathic Medicine" },
  { name: "Kirkwood Community College" },
  { name: "Knox College" },
  { name: "Knoxville College" },
  { name: "Kutztown University of Pennsylvania" },
  { name: "Laboratory Institute of Merchandising" },
  { name: "Lafayette College" },
  { name: "Lagrange College" },
  { name: "Lake Erie College" },
  { name: "Lake Forest College" },
  { name: "Lake Forest Graduate School of Management" },
  { name: "Lakeland College" },
  { name: "Lake Superior State University" },
  { name: "Lakeview College of Nursing" },
  { name: "Lamar University" },
  { name: "Lamar University - Port Arthur" },
  { name: "Lambuth University" },
  { name: "Lancaster Bible College" },
  { name: "Lander University" },
  { name: "Lane College" },
  { name: "Langston University" },
  { name: "La Roche College" },
  { name: "La Salle University" },
  { name: "Lasell College" },
  { name: "La Sierra University" },
  { name: "Laurus Technical Institute" },
  { name: "Lawrence Technological University" },
  { name: "Lawrence University" },
  { name: "Lebanon Valley College" },
  { name: "Lees-McRae College" },
  { name: "Lee University" },
  { name: "Lehigh Univervsity" },
  { name: "Le Moyne College" },
  { name: "Le Moyne-Owen College" },
  { name: "Lenoir-Rhyne College" },
  { name: "Lesley University" },
  { name: "Letourneau University" },
  { name: "Lewis and Clark College" },
  { name: "Lewis & Clark Community College" },
  { name: "Lewis-Clark State College" },
  { name: "Lewis University" },
  { name: "Liberty University" },
  { name: "Life Chiropractic College West" },
  { name: "Life University" },
  { name: "Limestone College" },
  { name: "Lincoln Memorial University" },
  { name: "Lincoln University Missouri" },
  { name: "Lincoln University Pennsylvania" },
  { name: "Lincoln University San Francisco" },
  { name: "Lindenwood University" },
  { name: "Lindsey Wilson College" },
  { name: "Linfield College" },
  { name: "Lipscomb University" },
  { name: "Livingstone College" },
  { name: "Lock Haven University of Pennsylvania" },
  { name: "Logan College of Chiropractic" },
  { name: "Loma Linda University" },
  { name: "Long Island University" },
  { name: "Long Island University, C.W. Post Campus" },
  { name: "Long Island University, Southampton College" },
  { name: "Longwood College" },
  { name: "Loras College" },
  { name: "Los Angeles College of Chiropractic" },
  { name: "Louisiana Baptist University" },
  { name: "Louisiana College" },
  {
    name: "Louisiana State University and Agricultural and Mechanical College",
  },
  { name: "Louisiana State University at Alexandria" },
  { name: "Louisiana State University at Eunice" },
  { name: "Louisiana State University Health Sciences Center New Orleans" },
  { name: "Louisiana State University in Shreveport" },
  { name: "Louisiana Tech University" },
  { name: "Lourdes College" },
  { name: "Loyola College in Maryland" },
  { name: "Loyola Marymount University" },
  { name: "Loyola University New Orleans" },
  { name: "Loyola University of Chicago" },
  { name: "Lubbock Christian University" },
  { name: "Lutheran Bible Institute of Seattle" },
  { name: "Luther College" },
  { name: "Lycoming College" },
  { name: "Lynchburg College" },
  { name: "Lyndon State College" },
  { name: "Lynn University" },
  { name: "Lyon College" },
  { name: "Macalester College" },
  { name: "MacMurray College" },
  { name: "Macon State College" },
  { name: "Madison University (Distance Education)" },
  { name: "Madonna University" },
  { name: "Maharishi University of Management" },
  { name: "Maine College of Art" },
  { name: "Maine Maritime Academy" },
  { name: "Malone College" },
  { name: "Manchester College" },
  { name: "Manhattan Christian College" },
  { name: "Manhattan College" },
  { name: "Manhattan School of Music" },
  { name: "Manhattanville College" },
  { name: "Mankato State University" },
  { name: "Mansfield University of Pennsylvania" },
  { name: "Maranatha Baptist Bible College" },
  { name: "Marian College" },
  { name: "Marian College of Fond Du Lac" },
  { name: "Marietta College" },
  { name: "Marist College" },
  { name: "Marlboro College" },
  { name: "Marquette University" },
  { name: "Marshall University" },
  { name: "Mars Hill College" },
  { name: "Martin Luther College" },
  { name: "Martin Methodist College" },
  { name: "Martin University" },
  { name: "Mary Baldwin College" },
  { name: "Marycrest International University" },
  { name: "Marygrove College" },
  { name: "Marylhurst University" },
  { name: "Marymount College New York" },
  { name: "Marymount Manhattan College" },
  { name: "Marymount University" },
  { name: "Maryville College" },
  { name: "Maryville University of St. Louis" },
  { name: "Mary Washington College" },
  { name: "Marywood University" },
  { name: "Massachusetts College of Art" },
  { name: "Massachusetts College of Liberal Arts" },
  { name: "Massachusetts College of Pharmacy and Allied Health Sciences" },
  { name: "Massachusetts Institute of Technology" },
  { name: "Massachusetts Maritime Academy" },
  { name: "Massachusetts School of Professional Psychology" },
  { name: "Mayo Graduate School" },
  { name: "Mayo Medical School" },
  { name: "Mayville State University" },
  { name: "McKendree College" },
  { name: "McMurry University" },
  { name: "McNeese State University" },
  { name: "McPherson College" },
  { name: "Medaille College" },
  { name: "Medcenter One College of Nursing" },
  { name: "Medical College of Georgia" },
  { name: "Medical College of Ohio" },
  { name: "Medical College of Pennsylvania and Hahnemann University" },
  { name: "Medical College of Wisconsin" },
  { name: "Medical University of South Carolina" },
  { name: "Meharry Medical College" },
  { name: "Memphis College of Art" },
  { name: "Menlo College" },
  { name: "Mennonite College of Nursing" },
  { name: "Mercer University" },
  { name: "Mercer University, Cecil B. Day Campus" },
  { name: "Mercy College" },
  { name: "Mercyhurst College" },
  { name: "Meredith College" },
  { name: "Merrimack College" },
  { name: "Mesa State College" },
  { name: "Messiah College" },
  { name: "Methodist College" },
  { name: "Metropolitan State College of Denver" },
  { name: "Metropolitan State University" },
  { name: "MGH Institute of Health Professions" },
  { name: "Miami Dade College" },
  { name: "Miami University of Ohio" },
  { name: "Miami University of Ohio - Hamilton" },
  { name: "Miami University of Ohio - Middletown" },
  { name: "Michigan School of Professional Psychology" },
  { name: "Michigan State University" },
  { name: "Michigan Technological University" },
  { name: "Mid-America Nazarene University" },
  { name: "Mid-American Bible College" },
  { name: "Mid-Continent Baptist Bible College" },
  { name: "Middlebury College" },
  { name: "Middle Tennessee State University" },
  { name: "Midland Lutheran College" },
  { name: "Midway College" },
  { name: "Midwestern State University" },
  { name: "Midwestern University" },
  { name: "Miles College" },
  { name: "Millennia Atlantic University" },
  { name: "Millersville University of Pennsylvania" },
  { name: "Milligan College" },
  { name: "Millikin University" },
  { name: "Millsaps College" },
  { name: "Mills College" },
  { name: "Mills Grae University" },
  { name: "Milwaukee Institute of Art and Design" },
  { name: "Milwaukee School of Engineering" },
  { name: "Minneapolis College of Art and Design" },
  { name: "Minnesota Bible College" },
  { name: "Minnesota School of Professional Psychology" },
  { name: "Minot State University" },
  { name: "Mississippi College" },
  { name: "Mississippi State University" },
  { name: "Mississippi University for Women" },
  { name: "Mississippi Valley State University" },
  { name: "Missouri Baptist College" },
  { name: "Missouri Southern State College" },
  { name: "Missouri Tech" },
  { name: "Missouri University of Science and Technology" },
  { name: "Missouri Valley College" },
  { name: "Missouri Western State College" },
  { name: "Molloy College" },
  { name: "Monmouth University" },
  { name: "Montana State University" },
  { name: "Montana State University - Billings" },
  { name: "Montana State University - Northern" },
  { name: "Montana Tech" },
  { name: "Montclair State University" },
  { name: "Monterey Institute of International Studies" },
  { name: "Montreat College" },
  { name: "Montserrat College of Art" },
  { name: "Moody Bible Institute" },
  { name: "Moore College of Art and Design" },
  { name: "Moorhead State University" },
  { name: "Moraine Valley Community College" },
  { name: "Moravian College" },
  { name: "Morehead State University" },
  { name: "Morehouse College" },
  { name: "Morehouse School of Medicine" },
  { name: "Morgan State University" },
  { name: "Morningside College" },
  { name: "Morris Brown College" },
  { name: "Morris College" },
  { name: "Morrison College" },
  { name: "Mountain State University" },
  { name: "Mount Aloysius College" },
  { name: "Mount Carmel College of Nursing" },
  { name: "Mount Holyoke College" },
  { name: "Mount Ida College" },
  { name: "Mount Marty College" },
  { name: "Mount Mary College" },
  { name: "Mount Mercy College" },
  { name: "Mount Olive College" },
  { name: "Mount Senario College" },
  { name: "Mount Sinai School of Medicine" },
  { name: "Mount St. Clare College" },
  { name: "Mount St. Mary College" },
  { name: "Mount St. Mary's College California" },
  { name: "Mount St. Mary's College Maryland" },
  { name: "Mount Union College" },
  { name: "Mount Vernon College" },
  { name: "Mount Vernon Nazarene College" },
  { name: "Muhlenberg College" },
  { name: "Multnomah Bible College" },
  { name: "Murray State University" },
  { name: "Muskingum College" },
  { name: "NAES College" },
  { name: "National American University" },
  { name: "National American University, Albuquerque" },
  { name: "National American University, Colorado Springs" },
  { name: "National American University, Denver" },
  { name: "National American University, Kansas City" },
  { name: "National American University, Roseville" },
  { name: "National American University, Sioux Falls" },
  { name: "National College of Chiropractic" },
  { name: "National College of Naturopathic Medicine" },
  { name: "National Defense University" },
  { name: "National Hispanic University" },
  { name: "National-Louis University" },
  { name: "National Technological University" },
  { name: "National Theatre Conservatory" },
  { name: "National University" },
  { name: "Naval Postgraduate School" },
  { name: "Nazarene Bible College" },
  { name: "Nazareth College" },
  { name: "Nebraska Christian College" },
  { name: "Nebraska Methodist College of Nursing and Allied Health" },
  { name: "Nebraska Wesleyan University" },
  { name: "Neumann College" },
  { name: "Newberry College" },
  { name: "New College of California" },
  { name: "New College of Florida" },
  { name: "New England College" },
  { name: "New England College of Optometry" },
  { name: "New England Conservatory of Music" },
  { name: "New England Institute of Technology" },
  { name: "New England School of Art and Design" },
  { name: "New England School of Communications" },
  { name: "New England School of Law" },
  { name: "New Hampshire College" },
  { name: "New Jersey City University" },
  { name: "New Jersey Institute of Technology" },
  { name: "Newman University" },
  { name: "New Mexico Highlands University" },
  { name: "New Mexico Institute of Mining and Technology" },
  { name: "New Mexico State University" },
  { name: "Newschool of Architecture and Design" },
  { name: "New York Academy of Art, Graduate School of Figurative Art" },
  { name: "New York Chiropractic College" },
  { name: "New York College of Podiatric Medicine" },
  { name: "New York Film Academy" },
  { name: "New York Institute of Technology" },
  { name: "New York Law School" },
  { name: "New York Medical College" },
  { name: "New York School of Interior Design" },
  { name: "New York University" },
  { name: "Niagara University" },
  { name: "Nicholls State University" },
  { name: "Nichols College" },
  { name: "Norfolk State University" },
  { name: "North Carolina Agricultural and Technical State University" },
  { name: "North Carolina Central University" },
  { name: "North Carolina School of the Arts" },
  { name: "North Carolina State University" },
  { name: "North Carolina Wesleyan College" },
  { name: "North Central Bible College" },
  { name: "North Central College" },
  { name: "Northcentral University" },
  { name: "North Dakota State University" },
  { name: "Northeastern Illinois University" },
  { name: "Northeastern Ohio University College of Medicine" },
  { name: "Northeastern State University" },
  { name: "Northeastern University" },
  { name: "Northern Arizona University" },
  { name: "Northern Illinois University" },
  { name: "Northern Kentucky University" },
  { name: "Northern Michigan University" },
  { name: "Northern State University" },
  { name: "Northern Virginia Community College" },
  { name: "Northface University" },
  { name: "North Georgia College" },
  { name: "North Greenville College" },
  { name: "Northland College" },
  { name: "North Park University" },
  { name: "Northwest Christian College" },
  { name: "Northwest College of Art" },
  { name: "Northwestern College Iowa" },
  { name: "Northwestern College of Chiropractic" },
  { name: "Northwestern College St. Paul" },
  { name: "Northwestern Oklahoma State University" },
  { name: "Northwestern State University of Louisiana" },
  { name: "Northwestern University" },
  { name: "Northwest Missouri State University" },
  { name: "Northwest Nazarene University" },
  { name: "Northwest University" },
  { name: "Northwood University" },
  { name: "Northwood University, Florida Campus" },
  { name: "Northwood University, Texas Campus" },
  { name: "Norwich University" },
  { name: "Notre Dame College" },
  { name: "Notre Dame College of Ohio" },
  { name: "Notre Dame de Namur University" },
  { name: "Nova Southeastern University" },
  { name: "Nyack College" },
  { name: "Oakland City University" },
  { name: "Oakland University" },
  { name: "Oakton Community College" },
  { name: "Oakwood College" },
  { name: "Oberlin College" },
  { name: "Occidental College" },
  { name: "Oglala Lakota College" },
  { name: "Oglethorpe University" },
  { name: "Ohio College of Podiatric Medicine" },
  { name: "Ohio Dominican College" },
  { name: "Ohio Northern University" },
  { name: "Ohio State University" },
  { name: "Ohio State University - Lima" },
  { name: "Ohio State University - Mansfield" },
  { name: "Ohio State University - Marion" },
  { name: "Ohio State University - Newark" },
  { name: "Ohio University" },
  { name: "Ohio University - Chillicothe" },
  { name: "Ohio University - Eastern" },
  { name: "Ohio University - Lancaster" },
  { name: "Ohio University - Southern" },
  { name: "Ohio University - Zanesville" },
  { name: "Ohio Valley College" },
  { name: "Ohio Wesleyan University" },
  { name: "Oklahoma Baptist University" },
  { name: "Oklahoma Christian University" },
  { name: "Oklahoma City University" },
  { name: "Oklahoma Panhandle State University" },
  { name: "Oklahoma State University" },
  { name: "Oklahoma State University Center for Health Sciences" },
  { name: "Oklahoma State University - Institute of Technology" },
  { name: "Oklahoma State University - Oklahoma City" },
  { name: "Oklahoma State University - Tulsa" },
  { name: "Old Dominion University" },
  { name: "Olive-Harvey College" },
  { name: "Olivet College" },
  { name: "Olivet Nazarene University" },
  { name: "O'More College of Design" },
  { name: "Oral Roberts University" },
  { name: "Oregon College of Arts and Crafts" },
  { name: "Oregon Graduate Institute of Science and Technology" },
  { name: "Oregon Health Sciences University" },
  { name: "Oregon Institute of Technology" },
  { name: "Oregon State University" },
  { name: "Otis College of Art & Design" },
  { name: "Ottawa University" },
  { name: "Otterbein College" },
  { name: "Ouachita Baptist University" },
  { name: "Our Lady of Holy Cross College" },
  { name: "Our Lady of the Lake University" },
  { name: "Ozark Christian College" },
  { name: "Pace University" },
  { name: "Pace University  Pleasantville/Briarcliff" },
  { name: "Pacifica Graduate Institute" },
  { name: "Pacific College of Oriental Medicine" },
  { name: "Pacific Graduate School of Psychology" },
  { name: "Pacific Lutheran University" },
  { name: "Pacific Northwest College of Art" },
  { name: "Pacific Oaks College" },
  { name: "Pacific Union College" },
  { name: "Pacific University" },
  { name: "Paier College of Art" },
  { name: "Paine College" },
  { name: "Palm Beach Atlantic University" },
  { name: "Palm Beach State College" },
  { name: "Palmer College of Chiropractic" },
  { name: "Palmer College of Chiropractic West" },
  { name: "Park College" },
  { name: "Parsons School of Design" },
  { name: "Paul Quinn College" },
  { name: "Peace College" },
  { name: "Pebble Hills University" },
  { name: "Pennsylvania Academy of the Fine Arts" },
  { name: "Pennsylvania College of Optometry" },
  { name: "Pennsylvania Institute of Technology" },
  { name: "Pennsylvania State University" },
  { name: "Pennsylvania State University - Abington" },
  { name: "Pennsylvania State University - Altoona" },
  { name: "Pennsylvania State University at Erie - Behrend College" },
  { name: "Pennsylvania State University at Harrisburg - The Capital College" },
  { name: "Pennsylvania State University - Berks-Lehigh Valley College" },
  { name: "Pennsylvania State University Delaware County" },
  { name: "Pennsylvania State University Great Valley" },
  { name: "Pennsylvania State University - Lehigh Valley" },
  { name: "Pennsylvania State University - Milton S.Hershey Medical Center" },
  { name: "Pennsylvania State University - Schuylkill" },
  { name: "Pepperdine University" },
  { name: "Peru State College" },
  { name: "Pfeiffer University" },
  { name: "Philadelphia College of Bible" },
  { name: "Philadelphia College of Osteopathic Medicine" },
  { name: "Philadelphia University" },
  { name: "Philander Smith College" },
  { name: "Phillips Graduate Institute" },
  { name: "Phillips University" },
  { name: "Piedmont Baptist College" },
  { name: "Piedmont College" },
  { name: "Pikeville College" },
  { name: "Pillsbury Baptist Bible College" },
  { name: "Pittsburg State University" },
  { name: "Pitzer College" },
  { name: "Plymouth State College" },
  { name: "Point Loma Nazarene College" },
  { name: "Point Park College" },
  { name: "Polytechnic University" },
  { name: "Polytechnic University, Long Island Campus" },
  { name: "Polytechnic University, Westchester Graduate Center" },
  { name: "Pomona College" },
  { name: "Portland Community College" },
  { name: "Portland State University" },
  { name: "Post University of Waterbury" },
  { name: "Prairie View Agricultural and Mechanical University" },
  { name: "Pratt Institute" },
  { name: "Presbyterian College" },
  { name: "Prescott College" },
  { name: "Preston University" },
  { name: "Princeton University" },
  { name: "Principia College" },
  { name: "Providence College" },
  { name: "Puget Sound Christian College" },
  { name: "Purdue University" },
  { name: "Purdue University Calumet" },
  { name: "Purdue University North Central" },
  { name: "Quantum-Veritas International University" },
  { name: "Queens College" },
  { name: "Quincy University" },
  { name: "Quinnipiac College" },
  { name: "Radford University" },
  { name: "Ramapo College of New Jersey" },
  { name: "Rand Graduate School of Policy Studies" },
  { name: "Randolph-Macon College" },
  { name: "Randolph-Macon Woman's College" },
  { name: "Rasmussen College" },
  { name: "Rasmussen College, Florida Campuses" },
  { name: "Rasmussen College, Illinois Campuses" },
  { name: "Rasmussen College, Minnesota Campuses" },
  { name: "Rasmussen College, North Dakota Campuses" },
  { name: "Rasmussen College, Wisconsin Campuses" },
  { name: "Reed College" },
  { name: "Reformed Bible College" },
  { name: "Regent International University" },
  { name: "Regent University" },
  { name: "Regis College" },
  { name: "Regis University" },
  { name: "Reinhardt College" },
  { name: "Rensselaer Polytechnic Institute" },
  { name: "Research College of Nursing - Rockhurst University" },
  { name: "Rhode Island College" },
  { name: "Rhode Island School of Design" },
  { name: "Rhodes College" },
  { name: "Rice University" },
  { name: "Richard Stockton College of New Jersey" },
  { name: "Rider University" },
  { name: "Ringling College of Art and Design" },
  { name: "Ripon College" },
  { name: "Rivier College" },
  { name: "Roanoke Bible College" },
  { name: "Roanoke College" },
  { name: "Robert Morris College" },
  { name: "Robert Morris College of Chicago" },
  { name: "Roberts Wesleyan College" },
  { name: "Rochester College" },
  { name: "Rochester Institute of Technology" },
  { name: "Rockford College" },
  { name: "Rockhurst College" },
  { name: "Rock Valley College" },
  { name: "Rocky Mountain College" },
  { name: "Rocky Mountain College of Art and Design" },
  { name: "Rogers State University" },
  { name: "Roger Williams University" },
  { name: "Rollins College" },
  { name: "Roosevelt University" },
  { name: "Rose-Hulman Institute of Technology" },
  { name: "Rosemont College" },
  { name: "Ross University, School of Medicine" },
  { name: "Rowan University" },
  { name: "Rush University" },
  { name: "Russel Sage College" },
  { name: "Rust College" },
  { name: "Rutgers, The State University of New Jersey" },
  { name: "Rutgers, The State University of New Jersey - Camden" },
  { name: "Rutgers, The State University of New Jersey - Newark" },
  { name: "Sacred Heart University" },
  { name: "Sage Graduate School" },
  { name: "Saginaw Valley State University" },
  { name: "Salem College" },
  { name: "Salem International University" },
  { name: "Salem State College" },
  { name: "Salem Teikyo University" },
  { name: "Salisbury State University" },
  { name: "Salve Regina University" },
  { name: "Samford University" },
  { name: "Sam Houston State University" },
  { name: "Samuel Merritt College" },
  { name: "San Diego State University" },
  { name: "San Diego University for Integrative Studies" },
  { name: "Sanford-Brown Institute" },
  { name: "San Francisco Art Institute" },
  { name: "San Francisco Conservatory of Music" },
  { name: "San Francisco State University" },
  { name: "San Joaquin College of Law" },
  { name: "San Jose Christian College" },
  { name: "San Jose State University" },
  { name: "Santa Clara University" },
  { name: "Sarah Lawrence College" },
  { name: "Savannah College of Art and Design" },
  { name: "Savannah State University" },
  { name: "Saybrook Institute" },
  { name: "Schiller International University" },
  { name: "Scholl College of Podiatric Medicine" },
  { name: "School for International Training" },
  { name: "School of the Museum of Fine Arts" },
  { name: "School of the Visual Arts" },
  { name: "Schreiner College" },
  { name: "Scripps College" },
  { name: "Seattle Pacific University" },
  { name: "Seattle University" },
  { name: "Seton Hall University" },
  { name: "Seton Hill College" },
  { name: "Shawnee Community College" },
  { name: "Shawnee State University" },
  { name: "Shaw University" },
  { name: "Sheldon Jackson College" },
  { name: "Shenandoah University" },
  { name: "Shepherd College" },
  { name: "Sherman College of Straight Chiropractic" },
  { name: "Shimer College" },
  { name: "Shippensburg University of Pennsylvania" },
  { name: "Shoreline Community College" },
  { name: "Shorter College" },
  { name: "Siena College" },
  { name: "Siena Heights University" },
  { name: "Sierra Nevada College" },
  { name: "Silver Lake College" },
  { name: "Simmons College" },
  { name: "Simon's Rock College" },
  { name: "Simpson College" },
  { name: "Simpson College Iowa" },
  { name: "Sinte Gleska University" },
  { name: "Skadron College" },
  { name: "Skidmore College" },
  { name: "Slippery Rock University" },
  { name: "Smith College" },
  { name: "Sojourner-Douglass College" },
  { name: "Soka University of America" },
  { name: "Sonoma State University" },
  { name: "South Carolina State University" },
  { name: "South Dakota School of Mines and Technology" },
  { name: "South Dakota State University" },
  { name: "Southeastern Baptist College" },
  { name: "Southeastern Bible College" },
  { name: "Southeastern College of the Assemblies of God" },
  { name: "Southeastern Louisiana University" },
  { name: "Southeastern Oklahoma State University" },
  { name: "Southeastern University" },
  { name: "Southeast Missouri State University" },
  { name: "Southern Adventist University" },
  { name: "Southern Arkansas University" },
  { name: "Southern California College" },
  { name: "Southern California College of Optometry" },
  { name: "Southern California Institute of Architecture" },
  { name: "Southern College of Optometry" },
  { name: "Southern Connecticut State University" },
  { name: "Southern Illinois University at Carbondale" },
  { name: "Southern Illinois University at Edwardsville" },
  { name: "Southern Methodist University" },
  { name: "Southern Nazarene University" },
  { name: "Southern New Hampshire University" },
  { name: "Southern Oregon University" },
  { name: "Southern Polytechnic State Univerisity" },
  { name: "Southern University - Baton Rouge" },
  { name: "Southern University - New Orleans" },
  { name: "Southern University - Shreveport" },
  { name: "Southern Utah University" },
  { name: "Southern Vermont College" },
  { name: "Southern Wesleyan University" },
  { name: "South Florida Bible College & Theological Seminary" },
  { name: "South Texas College of Law" },
  { name: "Southwest Baptist University" },
  { name: "Southwestern Adventist University" },
  { name: "Southwestern Assemblies of God University" },
  { name: "Southwestern Baptist Theological Seminary" },
  { name: "Southwestern Christian College" },
  { name: "Southwestern Christian University" },
  { name: "Southwestern College Kansas" },
  { name: "Southwestern College Santa Fe" },
  { name: "Southwestern Oklahoma State University" },
  { name: "Southwestern University" },
  { name: "Southwestern University School of Law" },
  { name: "Southwest Missouri State University" },
  { name: "Southwest Missouri State University - West Plains" },
  { name: "Southwest State University" },
  { name: "Southwest Texas State University" },
  { name: "Southwest University" },
  { name: "Spalding University" },
  { name: "Spelman College" },
  { name: "Spertus Institute of Jewish Studies" },
  { name: "Spring Arbor College" },
  { name: "Springfield College" },
  { name: "Spring Hill College" },
  { name: "St. Ambrose University" },
  { name: "Standford Online University" },
  { name: "Standford University" },
  { name: "St. Andrews Presbyterian College" },
  { name: "Stanford University" },
  { name: "St. Anselm College" },
  { name: "St. Anthony College of Nursing" },
  { name: "State University of New York at Albany" },
  { name: "State University of New York at Binghamton" },
  { name: "State University of New York at Buffalo" },
  { name: "State University of New York at New Paltz" },
  { name: "State University of New York at Oswego" },
  { name: "State University of New York at Stony Brook" },
  { name: "State University of New York College at Brockport" },
  { name: "State University of New York College at Cortland" },
  { name: "State University of New York College at Fredonia" },
  { name: "State University of New York College at Geneseo" },
  { name: "State University of New York College at Old Westbury" },
  { name: "State University of New York College at Oneonta" },
  { name: "State University of New York College at Plattsburgh" },
  { name: "State University of New York College at Potsdam" },
  { name: "State University of New York College at Purchase" },
  {
    name:
      "State University of New York College of Agriculture and Technology at Cobleskill",
  },
  {
    name:
      "State University of New York College of Environmental Science and Forestry",
  },
  { name: "State University of New York College of Optometry" },
  { name: "State University of New York College of Technology at Alfred" },
  { name: "State University of New York College of Technology at Farmingdale" },
  { name: "State University of New York Downstate Medical Center" },
  { name: "State University of New York Empire State College" },
  { name: "State University of New York Health Sience Centre Syracuse" },
  {
    name: "State University of New York Institute of Technology at Utica/Rome",
  },
  { name: "State University of New York Maritime College" },
  {
    name:
      "State University of New York School of Engineering and Applied Sciences",
  },
  { name: "State University of New York (SUNY)" },
  { name: "State University of New York Upstate Medical University " },
  { name: "State University of West Georgia" },
  { name: "St. Augustine's College North Carolina" },
  { name: "St. Bernard's Institute" },
  { name: "St. Bonaventure University" },
  { name: "St. Cloud State University" },
  { name: "St. Edwards University" },
  { name: "Stefan University" },
  { name: "Stephen F. Austin State University" },
  { name: "Stephens College" },
  { name: "Sterling College" },
  { name: "Stetson University" },
  { name: "Stevens Institute of Technology" },
  { name: "St. Francis College, Brooklyn Heights" },
  { name: "St. Francis College, Fort Wayne" },
  { name: "St. Francis College, Loretto" },
  { name: "St. Francis Medical Center College of Nursing" },
  { name: "St. George's University" },
  { name: "Stillman College" },
  { name: "St. John Fisher College" },
  { name: "St. John's College Maryland" },
  { name: "St. John's College New Mexico" },
  { name: "St. John's Seminary" },
  { name: "St. John's University" },
  { name: "St. Joseph College" },
  { name: "St. Joseph College of Nursing" },
  { name: "St. Joseph's College" },
  { name: "St. Joseph's College New York" },
  { name: "St. Joseph's College New York, Suffolk Campus" },
  { name: "St. Joseph's College of Maine" },
  { name: "St. Joseph's University" },
  { name: "St. Lawrence University" },
  { name: "St. Leo College" },
  { name: "St. Louis Christian College" },
  { name: "St. Louis College of Pharmacy" },
  { name: "St. Louis University" },
  { name: "St. Luke's College" },
  { name: "St. Martin's College" },
  { name: "St. Mary College" },
  { name: "St. Mary-of-the-Woods College" },
  { name: "St. Mary's College Indiana" },
  { name: "St. Mary's College of California" },
  { name: "St. Mary's College of Maryland" },
  { name: "St. Mary's University of Minnesota" },
  { name: "St. Mary's University of San Antonio" },
  { name: "St. Meinrad College" },
  { name: "St. Michael's College" },
  { name: "St. Norbert College" },
  { name: "St. Olaf College" },
  { name: "Stonehill College" },
  { name: "St. Paul's College" },
  { name: "St. Petersburg College" },
  { name: "St. Peter's College" },
  { name: "Strayer University" },
  { name: "St. Thomas Aquinas College" },
  { name: "St. Thomas University" },
  { name: "St. Vincent College" },
  { name: "St. Xavier University" },
  { name: "Suffolk University" },
  { name: "Sullivan College" },
  { name: "Sul Ross State University" },
  { name: "Susquehanna University" },
  { name: "Swarthmore College" },
  { name: "Sweet Briar College" },
  { name: "Syracuse University" },
  { name: "Tabor College" },
  { name: "Talladega College" },
  { name: "Tarleton State University" },
  { name: "Taylor University" },
  { name: "Taylor University, Fort Wayne Campus" },
  { name: "Teachers College, Columbia University" },
  { name: "Temple University" },
  { name: "Temple University School of Podiatric Medicine" },
  { name: "Tennessee State University" },
  { name: "Tennessee Technological University" },
  { name: "Tennessee Temple University" },
  { name: "Tennessee Wesleyan College" },
  { name: "Texas A&M International University" },
  { name: "Texas A&M University" },
  { name: "Texas A&M University - Commerce" },
  { name: "Texas A&M University - Corpus Christi" },
  { name: "Texas A&M University - Galveston" },
  { name: "Texas A&M University - Kingsville" },
  { name: "Texas A&M University - Texarkana" },
  { name: "Texas Chiropractic College" },
  { name: "Texas Christian University" },
  { name: "Texas College" },
  { name: "Texas College of Osteopathic Medicine" },
  { name: "Texas Lutheran University" },
  { name: "Texas Southern University" },
  { name: "Texas Tech University" },
  { name: "Texas Tech University Health Science Center" },
  { name: "Texas Wesleyan University" },
  { name: "Texas Woman's University" },
  { name: "The American College" },
  { name: "The Art Institute of Boston" },
  { name: "The Art Institutes International Portland" },
  { name: "The Art Institutes International San Francisco" },
  { name: "The Boston Conservatory" },
  { name: "The Catholic University of America" },
  { name: "The Chicago School of Professional Psychology" },
  { name: "The College of Insurance" },
  { name: "The College of New Jersey" },
  { name: "The College of Santa Fe" },
  { name: "The College of St. Scholastica" },
  { name: "The College of Westchester" },
  { name: "The College of Wooster" },
  { name: "The Cooper Union for the Advancement of Science and Art" },
  { name: "The Corcoran College of Art" },
  { name: "The Curtis Institute of Music" },
  { name: "The Defiance College" },
  { name: "The Dickinson School of Law" },
  { name: "The Illinois Institute of Art-Chicago" },
  { name: "The Johns Hopkins University" },
  { name: "The Juilliard School" },
  { name: "The Leadership Institute of Seattle" },
  { name: "The Maryland Institute, College of Art" },
  { name: "The Master's College" },
  { name: "The McGregor School of Antioch University" },
  { name: "The Naropa Institute" },
  { name: "The New School" },
  { name: "The Rockefeller University" },
  { name: "The School of the Art Institute of Chicago" },
  { name: "The Scripps Research Institute" },
  { name: "The Southern Christian University" },
  { name: "The Tulane University of New Orleans" },
  { name: "The Union Institute" },
  { name: "Thiel College" },
  { name: "Thomas A. Edison State College" },
  { name: "Thomas Aquinas College" },
  { name: "Thomas College Maine" },
  { name: "Thomas Jefferson University" },
  { name: "Thomas More College" },
  { name: "Thomas More College of Liberal Arts" },
  { name: "Thomas University" },
  { name: "Thunderbird School of Global Management" },
  { name: "Tiffin University" },
  { name: "Toccoa Falls College" },
  { name: "Tomball College" },
  { name: "Tougaloo College" },
  { name: "Touro College" },
  { name: "Touro University" },
  { name: "Towson University" },
  { name: "Transylvania University" },
  { name: "Trevecca Nazarene University" },
  { name: "Tri-College University" },
  { name: "Trident University" },
  { name: "Trinity Bible College" },
  { name: "Trinity Christian College" },
  { name: "Trinity College Connecticut" },
  { name: "Trinity College of Florida" },
  { name: "Trinity College of Vermont" },
  { name: "Trinity International University" },
  { name: "Trinity International University (Excel), Miami" },
  { name: "Trinity University" },
  { name: "Trinity University" },
  { name: "Tri-State University" },
  { name: "Triton College" },
  { name: "Troy University" },
  { name: "Troy University, Dothan" },
  { name: "Troy University, Montgomery" },
  { name: "Troy University, Phenix City" },
  { name: "Troy University, Troy" },
  { name: "Truman College" },
  { name: "Truman State University" },
  { name: "Tufts University" },
  { name: "Tui Online University" },
  { name: "Tusculum College" },
  { name: "Tuskegee University" },
  { name: "Uniformed Services Universty of the Health Sciences" },
  { name: "Union College" },
  { name: "Union College Kentucky" },
  { name: "Union College Nebraska" },
  { name: "Union Theological Seminary (UTS)" },
  { name: "Union University" },
  { name: "United States Air Force Academy" },
  { name: "United States Coast Guard Academy" },
  { name: "United States International University" },
  { name: "United States Merchant Marine Academy" },
  { name: "United States Military Academy" },
  { name: "United States Naval Academy" },
  { name: "United States Sports Academy" },
  { name: "Unity College" },
  { name: "University of Advancing Technology (UAT)" },
  { name: "University of Akron" },
  { name: "University of Alabama - Birmingham" },
  { name: "University of Alabama - Huntsville" },
  { name: "University of Alabama - Tuscaloosa" },
  { name: "University of Standardta" },
  { name: "University of Alaska - Anchorage" },
  { name: "University of Alaska - Fairbanks" },
  { name: "University of Alaska - Southeast" },
  { name: "University of Alaska (System)" },
  { name: "University of Arizona" },
  { name: "University of Arkansas at Fayetteville" },
  { name: "University of Arkansas at Little Rock" },
  { name: "University of Arkansas at Monticello" },
  { name: "University of Arkansas at Pine Bluff" },
  { name: "University of Arkansas for Medical Sciences" },
  { name: "University of Arkansas (System)" },
  { name: "University of Baltimore" },
  { name: "University of Bridgeport" },
  { name: "University of California, Berkeley" },
  { name: "University of California, Davis" },
  { name: "University of California, Hastings College of Law" },
  { name: "University of California, Irvine" },
  { name: "University of California, Los Angeles" },
  { name: "University of California, Merced" },
  { name: "University of California, Oakland" },
  { name: "University of California, Riverside" },
  { name: "University of California, San Diego" },
  { name: "University of California, San Francisco" },
  { name: "University of California, Santa Barbara" },
  { name: "University of California, Santa Cruz" },
  { name: "University of California System" },
  { name: "University of Central Arkansas" },
  { name: "University of Central Florida" },
  { name: "University of Central Missouri" },
  { name: "University of Central Oklahoma" },
  { name: "University of Central Texas" },
  { name: "University of Charleston" },
  { name: "University of Charleston South Carolina" },
  { name: "University of Chicago" },
  { name: "University of Cincinnati" },
  { name: "University of Colorado at Boulder" },
  { name: "University of Colorado at Colorado Springs" },
  { name: "University of Colorado at Denver" },
  { name: "University of Colorado Health Sciences Center" },
  { name: "University of Connecticut" },
  { name: "University of Connecticut at Avery Point" },
  { name: "University of Connecticut at Hartford" },
  { name: "University of Connecticut at Stamford" },
  { name: "University of Connecticut at Waterbury" },
  { name: "University of Connecticut Health Center" },
  { name: "University of Dallas" },
  { name: "University of Dayton" },
  { name: "University of Delaware" },
  { name: "University of Denver" },
  { name: "University of Detroit Mercy" },
  { name: "University of Dubuque" },
  { name: "University of Evansville" },
  { name: "University of Findlay" },
  { name: "University of Florida" },
  { name: "University of Georgia" },
  { name: "University of Great Falls" },
  { name: "University of Hartford" },
  { name: "University of Hawaii - Hilo" },
  { name: "University of Hawaii - Manoa" },
  { name: "University Of Hawaii - System" },
  { name: "University of Hawaii - West Oahu" },
  { name: "University of Health Sciences" },
  { name: "University of Houston" },
  { name: "University of Houston, Clear Lake" },
  { name: "University of Houston, Downtown" },
  { name: "University of Houston, Victoria" },
  { name: "University of Idaho" },
  { name: "University of Illinois" },
  { name: "University of Illinois at Chicago" },
  { name: "University of Illinois at Springfield" },
  { name: "University of Illinois at Urbana-Champaign" },
  { name: "University of Indianapolis" },
  { name: "University of Iowa" },
  { name: "University of Kansas" },
  { name: "University of Kentucky" },
  { name: "University of La Verne" },
  { name: "University of Louisiana at Lafayette" },
  { name: "University of Louisiana at Monroe" },
  { name: "University of Louisville" },
  { name: "University of Maine, Augusta" },
  { name: "University of Maine, Farmington" },
  { name: "University of Maine, Fort Kent" },
  { name: "University of Maine, Machias" },
  { name: "University of Maine, Orono" },
  { name: "University of Maine, Presque Isle" },
  { name: "University of Maine (System)" },
  { name: "University of Management & Technology" },
  { name: "University of Mary" },
  { name: "University of Mary Hardin-Baylor" },
  { name: "University of Maryland at Baltimore" },
  { name: "University of Maryland at College Park" },
  { name: "University of Maryland Baltimore County" },
  { name: "University of Maryland Eastern Shore" },
  { name: "University of Maryland Medicine" },
  { name: "University of Maryland (System)" },
  { name: "University of Maryland University College" },
  { name: "University of Massachusetts at Amherst" },
  { name: "University of Massachusetts at Boston" },
  { name: "University of Massachusetts at Dartmouth" },
  { name: "University of Massachusetts at Lowell" },
  { name: "University of Massachusetts Medical Center at Worcester" },
  { name: "University of Massachusetts (System)" },
  { name: "University of Medicine and Dentistry of New Jersey" },
  { name: "University of Memphis" },
  { name: "University of Miami" },
  { name: "University of Michigan - Ann Arbor" },
  { name: "University of Michigan - Dearborn" },
  { name: "University of Michigan - Flint" },
  { name: "University of Minnesota - Crookston" },
  { name: "University of Minnesota - Duluth" },
  { name: "University of Minnesota - Morris" },
  { name: "University of Minnesota - Twin Cities Campus" },
  { name: "University of Mississippi" },
  { name: "University of Mississippi Medical Center" },
  { name: "University of Missouri - Columbia" },
  { name: "University of Missouri - Kansas City" },
  { name: "University of Missouri - Saint Louis" },
  { name: "University of Mobile" },
  { name: "University of Montana" },
  { name: "University of Montana Western" },
  { name: "University of Montevallo" },
  { name: "University of Nebraska - Kearney" },
  { name: "University of Nebraska - Lincoln" },
  { name: "University of Nebraska Medical Center" },
  { name: "University of Nebraska - Omaha" },
  { name: "University of Nebraska (System)" },
  { name: "University of Nevada - Las Vegas" },
  { name: "University of Nevada - Reno" },
  { name: "University of New England" },
  { name: "University of New England, Westbrook College Campus" },
  { name: "University of New Hampshire" },
  { name: "University of New Hampshire - Manchester" },
  { name: "University of New Haven" },
  { name: "University of New Mexico" },
  { name: "University of New Orleans" },
  { name: "University of North Alabama" },
  { name: "University of North America" },
  { name: "University of North Carolina at Asheville" },
  { name: "University of North Carolina at Chapel Hill" },
  { name: "University of North Carolina at Charlotte" },
  { name: "University of North Carolina at Greensboro" },
  { name: "University of North Carolina at Pembroke" },
  { name: "University of North Carolina at Wilmington" },
  { name: "University of North Dakota" },
  { name: "University of Northern Colorado" },
  { name: "University of Northern Iowa" },
  { name: "University of Northern Virginia" },
  { name: "University of Northern Washington" },
  { name: "University of North Florida" },
  { name: "University of North Texas" },
  { name: "University of North Texas Health Science Center at Fort Worth" },
  { name: "University of NorthWest" },
  { name: "University of Notre Dame" },
  { name: "University of Oklahoma" },
  { name: "University of Oklahoma Health Sciences Center" },
  { name: "University of Oregon" },
  { name: "University of Osteopathic Medicine and Health Science" },
  { name: "University of Pennsylvania" },
  { name: "University of Phoenix" },
  { name: "University of Pittsburgh" },
  { name: "University of Pittsburgh at Bradford" },
  { name: "University of Pittsburgh at Greensburg" },
  { name: "University of Pittsburgh at Johnstown" },
  { name: "University of Portland" },
  { name: "University of Puget Sound" },
  { name: "University of Redlands" },
  { name: "University of Rhode Island" },
  { name: "University of Richmond" },
  { name: "University of Rio Grande" },
  { name: "University of Rochester" },
  { name: "University of San Diego" },
  { name: "University of San Francisco" },
  { name: "University of Science and Arts of Oklahoma" },
  { name: "University of Scranton" },
  { name: "University of Sioux Falls" },
  { name: "University of South Alabama" },
  { name: "University of South Carolina" },
  { name: "University of South Carolina - Aiken" },
  { name: "University of South Carolina - Beaufort" },
  { name: "University of South Carolina - Lancaster" },
  { name: "University of South Carolina - Salkehatchie" },
  { name: "University of South Carolina - Spartanburg" },
  { name: "University of South Carolina - Sumter" },
  { name: "University of South Carolina - Union" },
  { name: "University of South Dakota" },
  { name: "University of Southern California" },
  { name: "University of Southern Indiana" },
  { name: "University of Southern Maine" },
  { name: "University of Southern Mississippi" },
  { name: "University of South Florida" },
  { name: "University of St. Francis" },
  { name: "University of St. Thomas, Houston" },
  { name: "University of St. Thomas, St. Paul" },
  { name: "University of Tampa" },
  { name: "University of Tennessee - Chattanooga" },
  { name: "University of Tennessee - Knoxville" },
  { name: "University of Tennessee - Martin" },
  { name: "University of Tennessee - Memphis" },
  { name: "University of Tennessee Space Institute" },
  { name: "University of Texas" },
  { name: "University of Texas at Arlington" },
  { name: "University of Texas at Austin" },
  { name: "University of Texas at Brownsville" },
  { name: "University of Texas at Dallas" },
  { name: "University of Texas at El Paso" },
  { name: "University of Texas at San Antonio" },
  { name: "University of Texas at Tyler" },
  { name: "University of Texas Health Center at Houston" },
  { name: "University of Texas Health Center at Tyler" },
  { name: "University of Texas Health Science Center at San Antonio" },
  { name: "University of Texas M.D. Anderson Cancer Center" },
  { name: "University of Texas Medical Branch Galveston" },
  { name: "University of Texas of the Permian Basin" },
  { name: "University of Texas Pan American" },
  { name: "University of Texas Southwestern Medical Center at Dallas" },
  { name: "University of the Arts" },
  { name: "University of the District of Columbia" },
  { name: "University of the Incarnate World" },
  { name: "University of the Ozarks" },
  { name: "University of the Pacific" },
  { name: "University of the Sciences in Philadelphia" },
  { name: "University of the South" },
  { name: "University of the Southwest" },
  { name: "University of Toledo" },
  { name: "University of Tulsa" },
  { name: "University of Utah" },
  { name: "University of Vermont" },
  { name: "University of Virginia" },
  { name: "University of Virginia, College at Wise" },
  { name: "University of Washington" },
  { name: "University of Washington, Tacoma" },
  { name: "University of West Alabama" },
  { name: "University of West Florida" },
  { name: "University of West Los Angeles" },
  { name: "University of Wisconsin - Eau Claire" },
  { name: "University of Wisconsin - Green Bay" },
  { name: "University of Wisconsin - La Crosse" },
  { name: "University of Wisconsin - Madison" },
  { name: "University of Wisconsin - Milwaukee" },
  { name: "University of Wisconsin - Oshkosh" },
  { name: "University of Wisconsin - Parkside" },
  { name: "University of Wisconsin - Platteville" },
  { name: "University of Wisconsin - River Falls" },
  { name: "University of Wisconsin - Stevens Point" },
  { name: "University of Wisconsin - Stout" },
  { name: "University of Wisconsin - Superior" },
  { name: "University of Wisconsin - Whitewater" },
  { name: "University of Wyoming" },
  { name: "Upper Iowa University" },
  { name: "Urbana University" },
  { name: "Ursinus College" },
  { name: "Ursuline College" },
  { name: "Utah State University" },
  { name: "Utah Valley State College" },
  { name: "Utica College" },
  { name: "Valdosta State University" },
  { name: "Valley City State University" },
  { name: "Valley Forge Christian College" },
  { name: "Valparaiso University" },
  { name: "Vanderbilt University" },
  { name: "VanderCook College of Music" },
  { name: "Vanguard University of Southern California" },
  { name: "Vassar College" },
  { name: "Vennard College" },
  { name: "Vermont Law School" },
  { name: "Vermont Technical College" },
  { name: "Villa Julie College" },
  { name: "Villanova University" },
  { name: "Virginia College" },
  { name: "Virginia Commonwealth University" },
  { name: "Virginia Intermont College" },
  { name: "Virginia International University" },
  { name: "Virginia Military Institute" },
  {
    name: "Virginia Polytechnic Institute and State University (Virginia Tech)",
  },
  { name: "Virginia State University" },
  { name: "Virginia Union University" },
  { name: "Virginia Wesleyan College" },
  { name: "Viterbo College" },
  { name: "Voorhees College" },
  { name: "Wabash College" },
  { name: "Wagner College" },
  { name: "Wake Forest University" },
  { name: "Walden University" },
  { name: "Walla Walla College" },
  { name: "Walsh College of Accountancy and Business Administration" },
  { name: "Walsh University" },
  { name: "Warner Pacific College" },
  { name: "Warner Southern College" },
  { name: "Warren Wilson College" },
  { name: "Wartburg College" },
  { name: "Washburn University" },
  { name: "Washington and Lee University" },
  { name: "Washington Bible College" },
  { name: "Washington College" },
  { name: "Washington State University" },
  { name: "Washington State University, Spokane" },
  { name: "Washington State University, Tri-Cities" },
  { name: "Washington State University, Vancouver" },
  { name: "Washington University in St. Louis" },
  { name: "Wayland Baptist University" },
  { name: "Waynesburg College" },
  { name: "Wayne State College" },
  { name: "Wayne State University" },
  { name: "Webber College" },
  { name: "Webb Institute" },
  { name: "Weber State University" },
  { name: "Webster University" },
  { name: "Webster University North Florida" },
  { name: "Weill Medical College of Cornell University" },
  { name: "Wellesley College" },
  { name: "Wells College" },
  { name: "Wentworth Institute of Technology" },
  { name: "Wesleyan College" },
  { name: "Wesleyan University" },
  { name: "Wesley College" },
  { name: "Wesley College Mississippi" },
  { name: "Westbrook University " },
  { name: "West Chester University of Pennsylvania" },
  { name: "West Coast University" },
  { name: "Western Baptist College" },
  { name: "Western Bible College" },
  { name: "Western Carolina University" },
  { name: "Western Connecticut State University" },
  { name: "Western Governors University" },
  { name: "Western Illinois University" },
  { name: "Western International University" },
  { name: "Western Kentucky University" },
  { name: "Western Maryland College" },
  { name: "Western Michigan University" },
  { name: "Western New England College" },
  { name: "Western New Mexico University" },
  { name: "Western Oregon University" },
  { name: "Western State College" },
  { name: "Western States Chiropractic College" },
  { name: "Western State University College of Law" },
  { name: "Western State University College of Law - Orange County" },
  { name: "Western Washington University" },
  { name: "Westfield State College" },
  { name: "West Liberty State College" },
  { name: "Westminster College Fulton" },
  { name: "Westminster College New Wilmington" },
  { name: "Westminster College of Salt Lake City" },
  { name: "Westmont College" },
  { name: "West Suburban College of Nursing" },
  { name: "West Texas A&M University" },
  { name: "West Virginia School of Osteopathic Medicine" },
  { name: "West Virginia State College" },
  { name: "West Virginia University" },
  { name: "West Virginia University Institute of Technology" },
  { name: "West Virginia Wesleyan College" },
  { name: "Westwood College" },
  { name: "Wheaton College Massachusetts" },
  { name: "Wheeling Jesuit University" },
  { name: "Wheelock College" },
  { name: "Whitman College" },
  { name: "Whittier College" },
  { name: "Whitworth College" },
  { name: "Wichita State University" },
  { name: "Widener University" },
  { name: "Wilberforce University" },
  { name: "Wilbur Wright College" },
  { name: "Wiley College" },
  { name: "Wilkes University" },
  { name: "Willamette University" },
  { name: "William Carey College" },
  { name: "William Jewell College" },
  { name: "William Mitchell College of Law" },
  { name: "William Paterson University" },
  { name: "William Penn College" },
  { name: "Williams Baptist College" },
  { name: "Williams College" },
  { name: "William Tyndale College" },
  { name: "William Woods University" },
  { name: "Wilmington College" },
  { name: "Wilmington College" },
  { name: "Wilson College" },
  { name: "Wingate University" },
  { name: "Winona State University" },
  { name: "Winston-Salem State University" },
  { name: "Winthrop University" },
  { name: "Wisconsin Lutheran College" },
  { name: "Wisconsin School of Professional Psychology" },
  { name: "Wittenberg University" },
  { name: "Wofford College" },
  { name: "Woodbury University" },
  { name: "Worcester Polytechnic Institute" },
  { name: "Worcester State College" },
  { name: "Wright Institute" },
  { name: "Wright State University" },
  { name: "Xavier University" },
  { name: "Xavier University of Louisiana" },
  { name: "Yale University" },
  { name: "Yeshiva University" },
  { name: "York College Nebraska" },
  { name: "York College of Pennsylvania" },
  { name: "Yorker International University" },
  { name: "York University" },
  { name: "Youngstown State University" },
];
